.projectNameBox{
    min-height: 0;
    min-width: 0;
    max-width: 90%;
    position: relative;
    min-width: 250px;
}
.projectNameInnerText{
    display: flex;
    justify-content: center; 
    align-items: center;
}
@media (min-width: 800px){
    .projectNameInnerText{
        width: 600px;
        line-height: 52px;
    }
    .projectNameBox{
        top: 22px;
        height: 80px;
        width: 500px;
    }
}
@media (max-width: 800px){
    .projectNameInnerText{
        width: 420px;
        line-height: 34px;
    }
    .projectNameBox{
        height: 90px;
        width: 320px;
    }
}
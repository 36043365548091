.headerContainer{
    height: 100%;
    min-height: 100px;
    top: 0;
    position: absolute;
    /* overflow: hidden; */
}
.headerNormal{
    top: 0;
    right: 0;
    width: 100%;
    position: fixed;
    /* transition: 0.3s;     */
    height: 100px;
    z-index: 100;
}
.headerFirst{
    pointer-events: none;
    /* top: 0; */
    right: 0;
    width: 100%;
    /* position: fixed; thissssss*/
    /* transition: 0.3s; */   
    height: 100%;
    z-index: 100; 
}
.headerOnTop{
    position: fixed;
    top: 0
}
.headerFull{

}
.header{
    background-color: transparent;
     /* #121424; */
    /* 121424 */
}
.darkHeader{
    background-color: #121424; 
}
.onButtonsPage{
    text-align: center;
    height: 35px;
    width: 80px;
    border-radius: 50vh;
    pointer-events: auto;
    background-color: white;
    color: black;
    cursor: pointer;
    box-shadow: none;
    border: none;
    transition: 0s;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}
.onButtonsPage:hover{
    text-align: center;
    height: 35px;
    width: 80px;
    border-radius: 50vh;
    pointer-events: auto;
    background-color: transparent;
    color: white;
    cursor: pointer;
    box-shadow: none;
    border: 1px solid white;
    transition: 0s;
}
.onButtonsPage:active{
    text-align: center;
    height: 35px;
    width: 80px;
    border-radius: 50vh;
    pointer-events: auto;
    background: linear-gradient(rgba(255,255,255, 0.1), rgba(255,255,255, 0.2));
    color: white;
    cursor: pointer;
    box-shadow: none;
    border: 1px solid white;
    transition: 0s;
}
.notButtonsPage{
    text-align: center;
    height: 35px;
    width: 80px;
    border-radius: 50vh;
    background-color: transparent;
    color: white;
    pointer-events: auto;
    cursor: pointer;
    box-shadow: none;    
    border: none;
    transition: 0s;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}
.notButtonsPage:active{
    text-align: center;
    height: 35px;
    width: 80px;
    border-radius: 50vh;
    pointer-events: auto;
    background: linear-gradient(rgba(255,255,255, 0.1), rgba(255,255,255, 0.2));
    color: white;
    cursor: pointer;
    box-shadow: none;
    transition: 0s;
}
.cloud_1{
    aspect-ratio: 265.82437/43.524269;
    position: absolute;
    background-image: url('../img/svg/Header/MidClounds/Cloud_1.svg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
}
.cloud_2{
    aspect-ratio: 325.82712/42.94767;
    position: absolute;
    background-image: url('../img/svg/Header/MidClounds/Cloud_2.svg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
}
.cloud_3{
    aspect-ratio: 407.7565/54.316097;
    position: absolute;
    background-image: url('../img/svg/Header/MidClounds/Cloud_3.svg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
}
/* desktop */
@media (min-width: 800px){
    .cloud_1{
        bottom: max(20vh, 260px);
        /* left: 2vw; */
        width: 730px;
        margin: 0;
        padding: 0;
    }
    .cloud_2{
        bottom: max(20vh, 320px);
        /* right: -2vw; */
        width: 730px;
        margin: 0;
        padding: 0;
    }
    .cloud_3{
        bottom: max(25vh, 380px);
        /* left: 6vw; */
        margin: auto;
        width: 975px;
        margin: 0;
        padding: 0;
    }
.header{
    /* 0f1121 */
    width: 100%;
    height: 100%;
    padding: 0rem 1rem;
    transition: 0.3s ease-out;
  }
  .headerContentNav{
    top: 0;
    right: 100%;
    bottom: 0;
    width: auto;
    height: 100%;
    position: static;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: transparent;
    /* backdrop-filter: blur(5px); */
    transform: none;
    transition: 0.3s ease-in-out;
  }
  .headerContentNav ul{
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    /* margin-right: calc(80px + 0.5rem); */
    align-items: center;
    margin-bottom: 0;
}
.headerContentNav ul li:not(:last-child){
    /* margin-bottom: 0;
    margin-right: 40px; */
}
}
/* mobile */
@media (max-width: 800px){
    .cloud_1{
        bottom: max(20vh, 210px);
        /* left: -6vw; */
        width: 400px;
        margin: 0;
        padding: 0;
    }
    .cloud_2{
        bottom: max(20vh, 230px);
        /* right: -5vw; */
        width: 400px;
        margin: 0;
        padding: 0;
    }
    .cloud_3{
        bottom: max(25vh, 260px);
        /* left: 12vw; */
        margin: auto;
        width: 550px;
        margin: 0;
        padding: 0;
    }
    .header{
        width: 100%;
        height: 100%;
        padding: 0 1.5rem;
        transition: 0.3s ease-out;
      }
 }
 /* .headerContentNav ul li a{
    text-decoration: none;
    color: inherit;
    padding: 0.75rem 1.25rem;
    border-radius: 14px;
    transition: 0.3s ease-out;
    white-space: nowrap;
    pointer-events: auto;
}
.headerContentNav ul li a:hover{
    background: rgba(255,255,255, 0.1);
    text-decoration: none;
    color: inherit;
    transition: 0.3s ease-out;
}
.headerContentNav ul li a:active{
    border-radius: 20px;
    background: linear-gradient(rgba(255,255,255, 0.2), rgba(255,255,255, 0.3));
    text-decoration: none;
    color: inherit;
    padding: 0.75rem 1.25rem;
    transition: 0.3s ease-out;
} */
  .headerContent{
    overflow: hidden;
    color: white;
    margin: 0 auto;
    margin-left: 6px;
    margin-right: 6px;
    max-width: 1920px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;
  }
.headerButton{
    position: absolute;
    top: 28px;
    right: 8px;
    pointer-events: auto;
    width: 100px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    /* padding: 0.75rem 1.25rem; */
    padding: 0.1rem;
    border-radius: 5px;
    font-size: 1rem;
    font-family: inherit;
    background-color: rgba(109,185,249,1);
    color: black;
    border: 1px solid transparent;
    transition: 0.2s ease-out;
}
.headerButton:hover{
    border-color: rgba(109,185,249,1);
    background: rgba(255,255,255, 0.1);
    color: rgba(109,185,249,1);
}
.headerButton:active{
    border-radius: 10px;
    border-color: rgba(109,185,249,1);
    background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.3));
    color: rgba(109,185,249,1);
    text-align: center;
}
.headerAlignRect{
    width: 100%; 
    height: 50px; 
}
/* .locationRect{
    z-index: -1;
    position: fixed;
    background-color: aqua;
    width: 100px; 
    height: 50px; 
    border: 2px black solid; 
    border-radius: 50vh;  
} */
.header-wave-l3{
    background-image: url('../img/svg/Header/Wave/l3.svg');
    background-position: left bottom;
    left: 0;
}
.header-wave-l2{
    background-image: url('../img/svg/Header/Wave/l2.svg');
    background-position: left bottom;
    left: 0;
}
.header-wave-l1{
    background-image: url('../img/svg/Header/Wave/l1.svg');
    background-position: left bottom;
    right: 0;
}
.header-wave-r3{
    background-image: url('../img/svg/Header/Wave/r3.svg');
    background-position: right bottom;
    left: 0;
}
.header-wave-r2{
    background-image: url('../img/svg/Header/Wave/r2.svg');
    background-position: right bottom;
    right: 0;
}
.header-wave-r1{
    background-image: url('../img/svg/Header/Wave/r1.svg');
    background-position: right bottom;
    right: 0;
}
.header-wave{
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 200%;
    min-width: 3000px;
    background-size: 100% 100%;
}
.header-cloud-1{
    background-image: url('../img/svg/Header/Cloud/cloud1.svg');
    background-position: right top;
    right: 0px;
}
.header-cloud-2{
    background-image: url('../img/svg/Header/Cloud/cloud2.svg');
    background-position: left top;
    left: 0;
}
.header-cloud{
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 200%;
    min-width: 2500px;
    background-size: 100% 100%;
}
ul{
    justify-content: space-around;
    align-items: center;
    /* transition: 0.3s ease-out; */
}
@media (min-width: 800px){
.headerOnTop ul{
    justify-content: space-around;
    /* margin-right: 100px; */
    /* right: 200px; */
    left: 0;
    right: 0px;
    margin: auto;
    /* bottom:0px; */
    /* left: 0;
    margin: auto; */
    /* max(80px, 5vw); */
    height: 100px;
    /* width: 50px; */
    align-items: center;
}   
.headerOnTop ul{
    width: 550px;
    max-width: 44%;
    /* transition: width 0.3s; */
    /* transition: 0.3s ease-out; */
}   
.headerFull ul{
    justify-content: space-around;
    /* right: 0px; */
    left: 0;
    right: 0;
    margin: auto;
    height: 100px;
    /* bottom:0px; */
    align-items: center;
}
.headerFull ul{
    width: 550px;
    max-width: 44%;
    /* transition: width 0.3s; */
    /* transition: 0.3s ease-out; */
}  
.headerOnTop li:not(:last-child){
    /* li:not(:last-child){ */
    /* margin-right: 40px; */
    /* transition: 0.3s ease-out; */
}
.headerFull li:not(:last-child){
     /* li:not(:last-child){ */
    /* margin-right: min(100px, 5vw); */
    /* max(min(80px, 5vw),40px); */
    /* transition: 0.3s ease-out; */
}
}
.headerContentLogo{
    margin-top: -10px;
    font-size: 3rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
    /* background: #22aaff; */
      /* fallback for old browsers */
    /* background-image: linear-gradient(109.5deg,  rgba(76,221,242,1) 8.2%, rgba(92,121,255,1) 91.1% ); */
    /* background-image: linear-gradient(110deg,  
                                        rgba(76,221,242,1) 0%,                                                                         rgba(109,185,249,1) 44%,    
                                        rgba(109,185,249,1) 46%,  
                                        rgba(92,121,255,1) 100% 
                                        ); */
    text-align: center;
    display: block;
    /* rgba(109,185,249,1); */
    /* linear-gradient( 109.5deg,  rgba(76,221,242,1) 11.2%, rgba(92,121,255,1) 91.1% );  */

    
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .headerContentBackLogo{
    margin-top: -8px;
    font-size: 3rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background: #ffffff00;  /* fallback for old browsers */
    text-align: center;
    display: block;
    /* rgba(109,185,249,1); */
    /* linear-gradient( 109.5deg,  rgba(76,221,242,1) 11.2%, rgba(92,121,255,1) 91.1% );  */

    -webkit-text-stroke: 0px #dcedff;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
@media (max-width: 800px){
    ul {
        justify-content: center;
        align-content: center;
        align-items: center;
        justify-content: space-evenly;
        height: 100px;
        width: 80vw;
        max-width: 500px;
        /* right: 10px; */
        /* bottom: 30%; */
        left: 0;
        right: 0;
        margin: auto;
        /* top: 16%; */
        list-style-type: none;
        display: flex;
        flex-direction: row;
        padding: 0;
      }
      li{
        /* :not(:last-child */
        /* padding-bottom: max(20px, 2vh); */
        /* li:not(:last-child){ */
       /* margin-right: min(100px, 5vw); */
       /* max(min(80px, 5vw),40px); */
       transition: 0.3s ease-out;
   }
   /* .headerFull .headerContentLogo{
    font-size: 5rem;
    transition: 0.3s ease-out;
   } */
}
/* .headerOnTop .headerContentLogo{
    font-size: 2rem;
    position: absolute;
    left: 10px;
    top: 26px;
}
.headerFull .headerContentLogo{
    font-size: 3rem;
    position: absolute;
    left: 10px;
    top: 12px;
} */
/* .headerContentLogo{
    font-size: 3rem;
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
} */
@media (min-width: 0px){
    .islandBack{
        width: 80px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 190px;
        right: 35px;
        background-image: url('../img/svg/Header/islandBack.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .rock1{
        width: 550px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 65px;
        left: -100px;
        background-image: url('../img/svg/Header/Lighthouse.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .rock2{
        rotate: 10deg;
        left: 100px;
        width: 150px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 40px;
        background-image: url('../img/svg/Header/spike1.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .rock3{
        rotate: -20deg;
        left: 40px;
        width: 150px;
        aspect-ratio: 1;
        position: fixed;
        /* bottom: -60px; */
        bottom: -100%;
        background-image: url('../img/svg/Header/spike1.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
}
@media (min-width: 400px){
    .islandBack{
        width: 100px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 180px;
        right: 5px;
        background-image: url('../img/svg/Header/islandBack.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .rock1{
        width: 550px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 65px;
        left: -80px;
        background-image: url('../img/svg/Header/Lighthouse.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .rock2{
        rotate: 10deg;
        left: 200px;
        width: 100px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 80px;
        background-image: url('../img/svg/Header/spike1.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .rock3{
        rotate: -20deg;
        left: 120px;
        width: 150px;
        aspect-ratio: 1;
        position: fixed;
        /* bottom: -40px; */
        bottom: -100%;
        background-image: url('../img/svg/Header/spike1.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
}
@media(min-width: 530px){
    .islandBack{
        width: 120px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 160px;
        right: -5px;
        background-image: url('../img/svg/Header/islandBack.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
}
@media(min-width: 550px){
    .islandBack{
        width: 120px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 150px;
        right: -5px;
        background-image: url('../img/svg/Header/islandBack.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
}
@media(min-width: 600px){
    .islandBack{
        width: 120px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 130px;
        right: -5px;
        background-image: url('../img/svg/Header/islandBack.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
}
@media (min-width: 800px){
    .islandBack{
        width: 150px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 105px;
        right: 0px;
        background-image: url('../img/svg/Header/islandBack.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .rock1{
        width: 566px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 70px;
        left: -40px;
        background-image: url('../img/svg/Header/Lighthouse.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .rock2{
        rotate: 10deg;
        left: 200px;
        width: 166px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 30px;
        background-image: url('../img/svg/Header/spike1.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .rock3{
        rotate: -30deg;
        left: 220px;
        width: 60px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 60px;
        background-image: url('../img/svg/Header/spike1.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
}
@media (min-width: 1150px){
    .islandBack{
        width: 200px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 70px;
        right: 240px;
        background-image: url('../img/svg/Header/islandBack.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .rock1{
        width: 800px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 80px;
        left: 10px;
        background-image: url('../img/svg/Header/Lighthouse.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .rock2{
        rotate: 20deg;
        left: 250px;
        width: 200px;
        aspect-ratio: 1;
        position: fixed;
        bottom: 10px;
        background-image: url('../img/svg/Header/spike1.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .rock3{
        rotate: -30deg;
        left: 260px;
        width: 200px;
        aspect-ratio: 1;
        position: fixed;
        bottom: -80px;
        background-image: url('../img/svg/Header/spike1.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
    }
}
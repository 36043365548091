.parentDiv{
    /* display: flex;  
    justify-content: center;  
    align-items: center;  */
    background: radial-gradient(ellipse at center top, #6b41e8ff, transparent),
            radial-gradient(ellipse at right center, #c115e8ff, transparent),
            radial-gradient(ellipse at left bottom, #2c1eebff, transparent);
            background-color: #18112a;
            /* background: white; */
            scroll-snap-align: none;
            scroll-behavior: smooth;
}
.flip{
    transform: "rotate(180deg)"
}
.purpleSpacer{
    aspect-ratio: 1800/600;
    height: 100%;
    max-height: min(80vw, 1100px);
    min-height: 850px;
    width: 100%;
    /* min-width: 100%; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
}
hr{
    border: 0.8px solid white;
}
.purpleTop{
    background-image: url('../../img/svg/purple-top.svg');
}
.purpleBlob1{
    background-image: url('../../img/svg/purple-blob-1.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.scrollSenseDiv{
    height: 100px;
    width: 100%;
    background-color: transparent;
    position: absolute;
    top: 0px;
    margin: -20px;
}
.textBoxHeader{
    font-size: min(26px, 5vw);
    margin-bottom: 8px;
}
.textBoxText{
    /* font-size: min(16px, 3vw); */
    font-size: min(18px, 3vw);
    margin-bottom: 8px;
}
.textBox{
    background-color: #FFFFFF22;
    border-radius: 20px;
    min-width: 100px;
    min-height: 100px;
    max-width: min(90vw, 600px);
    text-align: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    backdrop-filter: blur(10px);
}
@media (min-width: 800px){
    .appstorelogo{
        top: 500px;
    }
    .purpleBlob1{
        /* transform: translate(-800px, 0); */
        position: absolute;
        width: 3200px;
        height: 3200px;
        right: -1300px;
        bottom: -800px;
    }
    .mic{
        /* aspect-ratio: 2601.3704/2536.1855; */
        top: 1900px;
        width: calc(100vw - 660px);
        height: 18%;
        padding: 50px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        right: 100px;
        background-image: url('../../img/svg/mic.svg');
    }
    .waveSVG{
        height: 500px;
        width: calc(100vw - 700px);
        top: 750px;
        position: absolute;
        right: 10px;
    }
    .swiftSVG{
        height: 300px;
        width: calc(50vw - 300px);
        top: 1450px;
        padding: 10px;
        position: absolute;
    }
    .lineWaveSVG{
        height: 400px;
        width: calc(100vw - 500px);
        top: 1480px;
        padding: 10px;
        position: absolute;
        right: 0px;
    }
    .star1{
        width: 100px;
        height: 100px;
        position: absolute;
        left: calc(50% + 230px);
        top: 150px;
        transition: 0.5s;
    }
    .star2{
        width: 75px;
        height: 75px;
        position: absolute;
        left: calc(50% + 210px);
        top: 70px;
        transition: 0.5s;
    }
    .star3{
        width: 50px;
        height: 50px;
        position: absolute;
        left: calc(50% + 300px);
        top: 110px;
        transition: 0.5s;
    }
    .star4{
        width: 80px;
        height: 80px;
        position: absolute;
        left: calc(50% - 300px);
        top: 50px;
        transition: 0.5s;
    }
    .star5{
        width: 80px;
        height: 80px;
        position: absolute;
        left: calc(50% - 300px);
        top: 230px;
        transition: 0.5s;
    }
    .star6{
        width: 50px;
        height: 50px;
        position: absolute;
        left: calc(50% + 290px);
        top: 350px;
        transition: 0.5s;
    }
    .topPillow{
        /* position: absolute; */
        /* width: calc(min(80vw, 700px)); */
        max-width: 90%;
        max-height: 90%;
        /* max-height: 80%; */
        /* top: 100px;
        padding: 100% */
        /* padding-left: calc(width / 2); */
        transition: 0.5s;
    }
    .topPillowContainer{
        position: absolute;
        width: 420px;
        height: 560px;
        margin-top: -80px;
        left: calc((100vw - 420px) / 2);
        /* margin-top: calc((min(40vw, 350px))); */
        display: flex;
        justify-content: center;  
        align-items: center;
    }
    .textBox1Pos{
        position: absolute;
        top: 850px;
        left: 50px;
    }
    .textBox2Pos{
        position: absolute;
        top: 1250px;
        left: 0px;
        right: 0px;
        margin: auto;
    }
    .textBox3Pos{
        position: absolute;
        top: 1850px;
        left: 50px;
    }
    .softPage{
        height: 3000px;
    }
}
@media (max-width: 800px){
    .mic{
        /* aspect-ratio: 2601.3704/2536.1855; */
        top: 1900px;
        width: 50vw;
        height: 20%;
        padding: 50px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        background-image: url('../../img/svg/mic.svg');
    }
    .waveSVG{
        height: 200px;
        width: 60vw;
        top: 970px;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
    }
    .swiftSVG{
        height: 220px;
        width: 220px;
        top: 1480px;
        padding: 10px;
        position: absolute;
    }
    .lineWaveSVG{
        height: 240px;
        width: 300px;
        max-width: 50vw;
        top: 1460px;
        padding: 10px;
        position: absolute;
        right: 50px;
    }
    .star1{
        width: 50px;
        height: 50px;
        position: absolute;
        left: calc(50% + 145px);
        top: 200px;
        transition: 0.5s;
    }
    .star2{
        width: 40px;
        height: 40px;
        position: absolute;
        left: calc(50% + 140px);
        top: 140px;
        transition: 0.5s;
    }
    .star3{
        width: 25px;
        height: 25px;
        position: absolute;
        left: calc(50% + 185px);
        top: 175px;
        transition: 0.5s;
    }
    .star4{
        width: 40px;
        height: 40px;
        position: absolute;
        left: calc(50% - 190px);
        top: 132px;
        transition: 0.5s;
    }
    .star5{
        width: 40px;
        height: 40px;
        position: absolute;
        left: calc(50% - 100px);
        top: 520px;
        transition: 0.5s;
    }
    .star6{
        width: 25px;
        height: 25px;
        position: absolute;
        left: calc(50% + 40px);
        top: 520px;
        transition: 0.5s;
    }
    .topPillow{
        /* position: absolute; */
        /* width: calc(min(80vw, 700px)); */
        max-width: 90%;
        max-height: 80%;
        /* top: 100px;
        padding: 100% */
        /* padding-left: calc(width / 2); */
        transition: 0.5s;
    }
    .topPillowContainer{
        position: absolute;
        width: 300px;
        height: 360px;
        margin-top: 24px;
        left: calc((100vw - 300px) / 2);
        /* margin-top: calc((min(40vw, 350px))); */
        display: flex;
        justify-content: center;  
        align-items: center;
    }
    .textBox1Pos{
        position: absolute;
        top: 600px;
        left: 0;
        right: 0;
        margin: auto
    }
    .textBox2Pos{
        position: absolute;
        top: 1200px;
        left: 0;
        right: 0;
        margin: auto
    }
    .textBox3Pos{
        position: absolute;
        top: 1700px;
        left: 0;
        right: 0;
        margin: auto
    }
    .softPage{
        height: 2400px;
    }
}
@media (max-width: 400px){
    .mic{
        /* aspect-ratio: 2601.3704/2536.1855; */
        top: 1850px;
        width: 50vw;
        height: 20%;
        padding: 50px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        background-image: url('../../img/svg/mic.svg');
    }
    .swiftSVG{
        height: 160px;
        width: 160px;
        top: 1480px;
        padding: 10px;
        position: absolute;
    }
    .star1{
        width: 50px;
        height: 50px;
        position: absolute;
        left: calc(50% + 32%);
        top: 180px;
        transition: 0.5s;
    }
    .star2{
        width: 40px;
        height: 40px;
        position: absolute;
        left: calc(50% + 38%);
        top: 140px;
        transition: 0.5s;
    }
    .star3{
        width: 25px;
        height: 25px;
        position: absolute;
        left: calc(50% + 44%);
        top: 175px;
        transition: 0.5s;
    }
    .star4{
        width: 40px;
        height: 40px;
        position: absolute;
        left: calc(50% - 46%);
        top: 126px;
        transition: 0.5s;
    }
    .star5{
        width: 40px;
        height: 40px;
        position: absolute;
        left: calc(50% - 30%);
        top: 510px;
        transition: 0.5s;
    }
    .star6{
        width: 25px;
        height: 25px;
        position: absolute;
        left: calc(50% + 10%);
        top: 520px;
        transition: 0.5s;
    }
}
.introTerminal_codeText:last-child .introTerminal_codeText_insertBox{
    background-color: rgb(157,156,157);
    /* margin-left: 1px; */
}
.introTerminal_codeText:last-child{
    background-color: rgb(100,100,100,0.05);
}
.introTerminal_outputText{
    color: #FFFFFFbb;
}
.introTerminal_outputText:last-child{
    color: #FFFFFFFF;
}

.newLogBackground{
    background-color: #FFFFFF55;
    transition: 0s;
}
.oldLogBackground{
    background-color: transparent;
    transition: 0.8s; 
}
/* .headerOnTop .introTerminal{
    position: absolute;
    right: 0;
    left: 0;
    top: -2000px;
    max-width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.headerFull .introTerminal{
    position: absolute;
    right: 0;
    left: 0;
    max-width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
} */
@media (min-width: 800px){
.introTerminal{
    position: absolute;
    right: 0;
    left: 0;
    /* bottom: max(250px, 32%); */
    max-width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.terminalScale{
    transform: scale(0.95);
    height: 363px;
}
}
@media (max-width: 800px){
    .introTerminal{
        position: absolute;
        /* right: 0; */
        margin-left: -9%;
        /* bottom: max(250px, 32%); */
        width: 118%;
        /* margin: auto; */
        display: flex;
        align-items: center;
        justify-content: center;
        /* transform: scale(0.8); */
    }
    .terminalScale{
        transform: scale(0.7);
        height: 276px;
    }
    }
.introTerminalIn *{
    text-wrap: wrap;
}
.titleText{
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: white;
  font-size: 60px;
  line-height: 60px;
  width: 100%;
  text-align: center;
  letter-spacing: -0.025em;
  margin-bottom: 0px;
  margin-top: 15px;

}
@media (max-width: 800px){
  .titleText{
    font-size: 50px;
    line-height: 50px;
  }
  .iosTopSpacer{
    margin-top: 0px;
  }
}
html{
  background-color: #121424;
  /* 0a0c1c */
  /* 121424 */
}
.section{
  /* background-color: #ffffff; */
}
.sectionHeader{
  text-align: center;
  position: absolute;
  width: 100%;
  font-size: min(16vw, 100px);
  letter-spacing: -0.025em;
  color: white;
  padding: 30px;
  text-shadow: 8px 8px #00000011;
  z-index: 10;
  /* text-decoration: underline; */
}
/* .projectLinksContainer{
  top: 630px;
  height: 1800px;
  max-height: 100%;
  width: 100%;
  position: absolute;
  background-color: blue;
} */
/* .innerProjectsLinksContainer{
  left: 0;
  right: 0;
  margin: auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: red;
} */
.innerProjectsLinksContainer *{
  width: 100%;
  height: 200px;
  top: 200px;
  z-index: 50;
  position: absolute;
  background-color: yellow;
  right: 200px;
}
.cyanEnder{
  background-image: url('./img/svg/cyan-ender.svg');
}
.tanEnder{
  background-image: url('./img/svg/tan-ender.svg');
}
.darkEnder{
  background-image: url('./img/svg/dark-ender.svg');
}
.purpleEnder{
  background-image: url('./img/svg/purple-ender.svg');
}
.belowAboutDiv{
  position: relative;
  width: 100%;
  height: 200px;
  /* background-color: white; */
}
.bottomSpacer{
  aspect-ratio: 1800/600;
  height: 600px;
  max-height: 30vw;
  width: 200px;
  min-width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  bottom: 0;
}
.aboutBottomSpacer{
  /* aspect-ratio: 1800/500; */
  /* height: 600px; */
  width: 100%;
  min-height: 150px;
  max-height: 100%;
  /* max-height: 100%; */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  position: absolute;
  bottom: 0;
  padding: 0;
  right: 0;
  left: 0;
}
.darkBottomSpacer{
  aspect-ratio: 1800/600;
  height: 600px;
  width: 200px;
  max-height: 30vw;
  min-width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  bottom: 0px;
  pointer-events: none;
}
.topSpacer{
  aspect-ratio: 1800/600;
  height: 350px;
  max-height: 50vw;
  width: 200px;
  min-width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: -5px;
  transform: rotate(180deg);
}
.darkTopSpacer{
  aspect-ratio: 1800/600;
  height: 600px;
  width: 200px;
  max-height: 30vw;
  min-width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: -5px;
  pointer-events: none;
  transform: rotate(180deg);
}
.purpleTopSoftSleep{
  aspect-ratio: 1800/600;
  height: 350px;
  width: 200px;
  max-height: 50vw;
  min-width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: -5px;
  pointer-events: none;
  transform: rotate(180deg);
}
.purpleBottom{
  background-image: url('./img/svg/purple-bottom.svg');
  background-size: contain;
  margin: 0;
  padding: 0;
  bottom: 0;
  bottom: -1px;
  pointer-events: none;
}
.cyanBottom{
  background-image: url('./img/svg/cyan-bottom.svg');
  background-size: contain;
  margin: 0;
  padding: 0;
  bottom: 0;
  bottom: -2px;
  pointer-events: none;
}
.tanBottom{
  background-image: url('./img/svg/tan-bottom.svg');
  pointer-events: none;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sectionContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sectionContainer {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}
.topUI{
  width: 100%;
  position: fixed;
  z-index: 500;
}
html{
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
  overflow: hidden;
}
body {
  -webkit-overflow-scrolling: touch;
  }
.sectionContainer{
  /* -webkit-overflow-scrolling: touch; */
  /* overflow-y: scroll;
  scroll-snap-type: y mandatory;
  background-color: aqua; */
  height: 100dvh;
  overflow: scroll;
  scroll-behavior: smooth;
}
.scrollSnapContainer{
  scroll-snap-type: y mandatory;
}
.offsetRect{
  background-color: red;
}
.blue{
  background-color: blue;
}
.headerAlignRect{
  background-color: black;
}
.section{
  /* scroll-behavior: smooth; */
  height: calc(100dvh - 100px);
  scroll-snap-align: start;
  overflow: hidden;
  scroll-margin-top: 100px;
  position: relative;
  /* scroll-padding-top: 100px; */
  /* padding-top: 120px; */
}
.pageIntersectionObserver{
  top: 50%;
  width: 100%;
  position: absolute;
  height: 100px;
  background-color: transparent;
  z-index: -1;
}
.pageBottomIntersectionObserver{
  bottom: 80vh;
  width: 100%;
  position: absolute;
  height: 100px;
  background-color: transparent;
}
.lastSection{
  /* scroll-behavior: smooth; */
  height: calc(100dvh - 100px);
  scroll-snap-align: start;
  overflow: hidden;
  scroll-margin-top: 100px;
  position: relative;
  /* scroll-padding-top: 100px; */
  /* padding-top: 120px; */
}
.firstSection{
  /* scroll-behavior: smooth; */
  height: calc(100vh);
  scroll-snap-align: start;
  overflow: hidden;
  /* scroll-margin-top: 100px; */
  position: relative;
  /* scroll-padding-top: 100px; */
  /* padding-top: 120px; */
} 
.longSection{
  /* scroll-snap-align: start;
  scroll-behavior: smooth; */
  scroll-snap-align: start;
  scroll-margin-top: 100px;
  /* scroll-padding: -100px; */
  min-height: calc(100dvh+ 100px);
  position: relative;
  /* scroll-snap-stop: always; */
  overflow: hidden;
  scroll-snap-type: mandatory;
}
@media (min-width: 800px){
.appTitle{
  /* position: absolute; */
  /* top: 560px; */
  padding-top: 10px;
  width: 100%;
  transition: 0.5s ease-out;
  color: white;
  /* text-shadow: 1px 1px #00000033; */
}
.appStoreLogo1{
  top: 670px;
  right: 0px;
  left: 0px;
  width: 180px;
  margin: auto;
  position: absolute;
  z-index: 50;
}
.appStoreLogo2{
  top: 750px;
  right: 0px;
  left: 0px;
  width: 180px;
  margin: auto;
  position: absolute;
  z-index: 50;
}  
.appStoreLogo1_tt{
  top: 450px;
  right: 0px;
  left: 0px;
  width: 180px;
  margin: auto;
  position: absolute;
  z-index: 50;
}
.appStoreLogo2_tt{
  top: 530px;
  right: 0px;
  left: 0px;
  width: 180px;
  margin: auto;
  position: absolute;
  z-index: 50;
}  
}
@media (max-width: 800px){
  .appTitle{
    /* position: absolute; */
    padding-top: 10px;
    /* top: 370px; */
    /* width: 100%; */
    transition: 0.5s ease-out;
    color: white;
    /* text-shadow: 1px 1px #00000033; */
  }
  .appStoreLogo1{
    top: 420px;
    right: 0px;
    left: 0px;
    width: 160px;
    margin: auto;
    position: absolute;
    z-index: 50;
  }
  .appStoreLogo2{
    top: 500px;
    right: 0px;
    left: 0px;
    width: 160px;
    margin: auto;
    position: absolute;
    z-index: 50;
  } 
  .appStoreLogo1_tt{
    top: 420px;
    right: 0px;
    left: 0px;
    width: 160px;
    margin: auto;
    position: absolute;
    z-index: 50;
  }
  .appStoreLogo2_tt{
    top: 500px;
    right: 0px;
    left: 0px;
    width: 160px;
    margin: auto;
    position: absolute;
    z-index: 50;
  }  
  }
  @supports (-webkit-touch-callout: none) {
    .firstSection{
      scroll-behavior: smooth;
      height: calc(100vh - 100px);
      scroll-snap-align: start;
      overflow: hidden;
      /* scroll-margin-top: 100px; */
      position: relative;
      /* scroll-padding-top: 100px; */
      /* padding-top: 120px; */
  } 
  .section{
    /* scroll-behavior: smooth; */
    height: calc(100vh - 200px);
    scroll-snap-align: start;
    overflow: hidden;
    scroll-margin-top: 100px;
    position: relative;
    /* scroll-padding-top: 100px; */
    /* padding-top: 120px; */
  }
  .longSection{
    scroll-behavior: smooth;
    scroll-snap-align: start;
    scroll-margin-top: 100px;
    /* scroll-snap-type: y none; */
    /* scroll-padding: -100px; */
    /* min-height: calc(100vh - 100px); */
    position: relative;
    /* scroll-snap-stop: always; */
    overflow: hidden;
  }
  .lastSection{
    /* scroll-behavior: smooth; */
    height: calc(100vh - 200px);
    scroll-snap-align: start;
    overflow: hidden;
    scroll-margin-top: 100px;
    position: relative;
    /* scroll-padding-top: 100px; */
    /* padding-top: 120px; */
  }
  }
.headerSection{
  height: 100vh;
  scroll-snap-align: start;
  overflow: hidden;
}
.sectionPadded{
    padding-top: 100px;
    /* transition: 0.3s; */
}
.appTitle{
  text-align: center;
}
*{
  box-sizing: border-box;
  margin: 0;
}
.dark-text{
  color: rgb(30,30,30);
}
.light-text{
  color: rgb(255,255,255);
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}